import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../components/Layout';
import { AboutSidebarLinks } from '../../helpers/constants';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout pageTitle="Press" sidebarLinks={AboutSidebarLinks} activeHeaderLink="About" mdxType="Layout">
      <h1>{`EEW Reports`}</h1>
      <ul>
        <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
              "href": "https://envirodatagov.org/?post_type=publication&p=6167&preview=true"
            }}>{`Democratizing Data: Environmental Enforcement Watch's Report Cards for Congressional Oversight of the EPA`}</a></strong></li>
        <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
              "href": "https://envirodatagov.org/more-permission-to-pollute-the-decline-of-epa-enforcement-and-industry-compliance-during-covid"
            }}>{`More Permission to Pollute: The Decline of EPA Enforcement and Industry Compliance during COVID`}</a></strong>{` `}</li>
      </ul>
      <h1>{`EEW Events`}</h1>
      <ul>
        <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
              "href": "https://youtu.be/9Ot7uFA4ucQ"
            }}>{`Democratizing Environmental Data: Panel Discussion & Report Release (10/22/2020)`}</a></strong></li>
        <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
              "href": "https://www.youtube.com/watch?v=dUV3NweSWTs&list=PLtsP3g9LafVv78TIa42xr591-4CfKMYQO&index=21&t=212s"
            }}>{`EEW Public Hours workshop 4 (9/10/2020)`}</a></strong></li>
        <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
              "href": "https://www.youtube.com/watch?v=Gp1aDeXMays&list=PLtsP3g9LafVv78TIa42xr591-4CfKMYQO&index=31"
            }}>{`EEW Public Hours workshop 1 (8/20/2020)`}</a></strong></li>
        <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
              "href": "https://www.youtube.com/watch?v=rCs35rmDo9c&list=PLtsP3g9LafVv78TIa42xr591-4CfKMYQO&index=49&t=1678s"
            }}>{`Sunrise Boston Hub workshop (7/18/2020)`}</a></strong></li>
      </ul>
      <h1>{`EEW Blog Posts`}</h1>
      <ul>
        <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
              "href": "https://envirodatagov.org/bio-labs-toxic-record-presages-chlorine-fueled-fire-following-hurricane-laura"
            }}>{`Bio-Lab's Toxic Record Presages Chlorine-Fueled Fire Following Hurricane Laura`}</a></strong></li>
        <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
              "href": "https://envirodatagov.org/practicing-our-right-to-know-together-review-of-eew-congressional-report-card-workshops-to-date"
            }}>{`Practicing Our Right to Know, Together — Review of EEW Congressional Report Card Workshops`}</a></strong></li>
      </ul>
      <h1>{`EEW Full Press Coverage`}</h1>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://insideepa.com/daily-feed/edgi-report-sees-major-monitoring-gaps-during-pandemic"
          }}>{`EDGI report sees major monitoring gaps during pandemic`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.washingtonpost.com/politics/2020/08/25/energy-202-ed-markey-uses-climate-change-flip-insurgent-script-joe-kennedy-mass-race"
          }}>{`Ed Markey uses climate change to flip insurgent script on Joe Kennedy in Mass. race`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://apnews.com/article/3bf753f9036e7d88f4746b1a36c1ddc4"
          }}>{`Thousands allowed to bypass environmental rules in pandemic`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.washingtonpost.com/climate-environment/thousands-allowed-to-bypass-environmental-rules-in-pandemic/2020/08/24/187c8772-e5c2-11ea-bf44-0d31c85838a5_story.html"
          }}>{`Thousands allowed to bypass environmental rules in pandemic`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.usnews.com/news/politics/articles/2020-08-24/thousands-allowed-to-bypass-environmental-rules-in-pandemic"
          }}>{`Thousands allowed to bypass environmental rules in pandemic`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.aljazeera.com/economy/2020/08/24/thousands-of-firms-in-us-bypass-environmental-rules-report"
          }}>{`Thousands allowed to bypass environmental rules in pandemic`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.atholdailynews.com/Thousands-allowed-to-bypass-environmental-rules-in-pandemic-35891139"
          }}>{`Thousands allowed to bypass environmental rules in pandemic`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://minnesota.cbslocal.com/2020/08/24/some-minnesota-farms-allowed-to-bypass-environmental-rules-during-pandemic"
          }}>{`Some Minnesota Farms Allowed To Bypass Environmental Rules During Pandemic`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://columbiabasinherald.com/news/2020/aug/23/thousands-allowed-to-bypass-environmental-2"
          }}>{`Thousands allowed to bypass environmental rules in pandemic`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.startribune.com/thousands-allowed-to-bypass-environmental-rules-in-pandemic/572202332/?refresh=true"
          }}>{`Thousands allowed to bypass environmental rules in pandemic`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.timesfreepress.com/news/business/aroundregion/story/2020/aug/24/thousands-allowed-bypass-environmental-rules/530655"
          }}>{`Thousands allowed to bypass environmental rules in pandemic`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.theadvocate.com/baton_rouge/news/environment/article_17d63f90-e6e7-11ea-b192-3b37d164026c.html"
          }}>{`Oil and gas companies, others allowed to bypass environmental rules amid pandemic, AP finds`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.wibw.com/2020/08/24/thousands-of-facilities-allowed-to-bypass-environmental-rules-in-pandemic"
          }}>{`Thousands of facilities allowed to bypass environmental rules in pandemic`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://earther.gizmodo.com/trump-has-overseen-a-pollution-nightmare-including-in-1845449686"
          }}>{`Trump Has Overseen a Pollution Nightmare, Including in the Backyards of Members of Congress Overseeing the EPA`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.pennlive.com/opinion/2020/10/pennsylvania-water-is-not-what-it-used-to-be-opinion.html"
          }}>{`Pennsylvania water is not what it used to be | Opinion`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://truthout.org/articles/what-does-equitable-climate-policy-actually-look-like"
          }}>{`What Does Equitable Climate Policy Actually Look Like?`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.ecowatch.com/climate-justice-us-policy-2647869650.html?rebelltitem=7#rebelltitem7"
          }}>{`What Does Equitable Climate Policy Actually Look Like?`}</a></li>
      </ul>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      